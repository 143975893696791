// For a visual of the color maps, refer to https://matplotlib.org/stable/tutorials/colors/colormaps.html

function componentToHex(value) {
    var hex = value.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(rgb) {
    return (
        "#" +
        componentToHex(rgb[0]) +
        componentToHex(rgb[1]) +
        componentToHex(rgb[2])
    );
}

export function extractLegend(colormap, steps = 5) {
    let indices = []
    for(let i = 0; i < steps; i++) {
        indices[i] = Math.round(i * (255.0 / (steps-1)))
    }

    return indices.map(index =>
        rgbToHex(colormaps[colormap][index])
    );
}

export const colormaps = {
    hot: [
        [10, 0, 0, 255],
        [13, 0, 0, 255],
        [15, 0, 0, 255],
        [18, 0, 0, 255],
        [21, 0, 0, 255],
        [23, 0, 0, 255],
        [26, 0, 0, 255],
        [28, 0, 0, 255],
        [31, 0, 0, 255],
        [34, 0, 0, 255],
        [36, 0, 0, 255],
        [39, 0, 0, 255],
        [42, 0, 0, 255],
        [44, 0, 0, 255],
        [47, 0, 0, 255],
        [49, 0, 0, 255],
        [52, 0, 0, 255],
        [55, 0, 0, 255],
        [57, 0, 0, 255],
        [60, 0, 0, 255],
        [63, 0, 0, 255],
        [65, 0, 0, 255],
        [68, 0, 0, 255],
        [70, 0, 0, 255],
        [73, 0, 0, 255],
        [76, 0, 0, 255],
        [78, 0, 0, 255],
        [81, 0, 0, 255],
        [84, 0, 0, 255],
        [86, 0, 0, 255],
        [89, 0, 0, 255],
        [91, 0, 0, 255],
        [94, 0, 0, 255],
        [97, 0, 0, 255],
        [99, 0, 0, 255],
        [102, 0, 0, 255],
        [105, 0, 0, 255],
        [107, 0, 0, 255],
        [110, 0, 0, 255],
        [112, 0, 0, 255],
        [115, 0, 0, 255],
        [118, 0, 0, 255],
        [120, 0, 0, 255],
        [123, 0, 0, 255],
        [126, 0, 0, 255],
        [128, 0, 0, 255],
        [131, 0, 0, 255],
        [133, 0, 0, 255],
        [136, 0, 0, 255],
        [139, 0, 0, 255],
        [141, 0, 0, 255],
        [144, 0, 0, 255],
        [147, 0, 0, 255],
        [149, 0, 0, 255],
        [152, 0, 0, 255],
        [154, 0, 0, 255],
        [157, 0, 0, 255],
        [160, 0, 0, 255],
        [162, 0, 0, 255],
        [165, 0, 0, 255],
        [168, 0, 0, 255],
        [170, 0, 0, 255],
        [173, 0, 0, 255],
        [175, 0, 0, 255],
        [178, 0, 0, 255],
        [181, 0, 0, 255],
        [183, 0, 0, 255],
        [186, 0, 0, 255],
        [189, 0, 0, 255],
        [191, 0, 0, 255],
        [194, 0, 0, 255],
        [196, 0, 0, 255],
        [199, 0, 0, 255],
        [202, 0, 0, 255],
        [204, 0, 0, 255],
        [207, 0, 0, 255],
        [210, 0, 0, 255],
        [212, 0, 0, 255],
        [215, 0, 0, 255],
        [217, 0, 0, 255],
        [220, 0, 0, 255],
        [223, 0, 0, 255],
        [225, 0, 0, 255],
        [228, 0, 0, 255],
        [231, 0, 0, 255],
        [233, 0, 0, 255],
        [236, 0, 0, 255],
        [238, 0, 0, 255],
        [241, 0, 0, 255],
        [244, 0, 0, 255],
        [246, 0, 0, 255],
        [249, 0, 0, 255],
        [252, 0, 0, 255],
        [254, 0, 0, 255],
        [255, 2, 0, 255],
        [255, 5, 0, 255],
        [255, 7, 0, 255],
        [255, 10, 0, 255],
        [255, 12, 0, 255],
        [255, 15, 0, 255],
        [255, 18, 0, 255],
        [255, 20, 0, 255],
        [255, 23, 0, 255],
        [255, 26, 0, 255],
        [255, 28, 0, 255],
        [255, 31, 0, 255],
        [255, 33, 0, 255],
        [255, 36, 0, 255],
        [255, 39, 0, 255],
        [255, 41, 0, 255],
        [255, 44, 0, 255],
        [255, 47, 0, 255],
        [255, 49, 0, 255],
        [255, 52, 0, 255],
        [255, 54, 0, 255],
        [255, 57, 0, 255],
        [255, 60, 0, 255],
        [255, 62, 0, 255],
        [255, 65, 0, 255],
        [255, 68, 0, 255],
        [255, 70, 0, 255],
        [255, 73, 0, 255],
        [255, 75, 0, 255],
        [255, 78, 0, 255],
        [255, 81, 0, 255],
        [255, 83, 0, 255],
        [255, 86, 0, 255],
        [255, 89, 0, 255],
        [255, 91, 0, 255],
        [255, 94, 0, 255],
        [255, 96, 0, 255],
        [255, 99, 0, 255],
        [255, 102, 0, 255],
        [255, 104, 0, 255],
        [255, 107, 0, 255],
        [255, 110, 0, 255],
        [255, 112, 0, 255],
        [255, 115, 0, 255],
        [255, 117, 0, 255],
        [255, 120, 0, 255],
        [255, 123, 0, 255],
        [255, 125, 0, 255],
        [255, 128, 0, 255],
        [255, 131, 0, 255],
        [255, 133, 0, 255],
        [255, 136, 0, 255],
        [255, 138, 0, 255],
        [255, 141, 0, 255],
        [255, 144, 0, 255],
        [255, 146, 0, 255],
        [255, 149, 0, 255],
        [255, 151, 0, 255],
        [255, 154, 0, 255],
        [255, 157, 0, 255],
        [255, 159, 0, 255],
        [255, 162, 0, 255],
        [255, 165, 0, 255],
        [255, 167, 0, 255],
        [255, 170, 0, 255],
        [255, 172, 0, 255],
        [255, 175, 0, 255],
        [255, 178, 0, 255],
        [255, 180, 0, 255],
        [255, 183, 0, 255],
        [255, 186, 0, 255],
        [255, 188, 0, 255],
        [255, 191, 0, 255],
        [255, 193, 0, 255],
        [255, 196, 0, 255],
        [255, 199, 0, 255],
        [255, 201, 0, 255],
        [255, 204, 0, 255],
        [255, 207, 0, 255],
        [255, 209, 0, 255],
        [255, 212, 0, 255],
        [255, 214, 0, 255],
        [255, 217, 0, 255],
        [255, 220, 0, 255],
        [255, 222, 0, 255],
        [255, 225, 0, 255],
        [255, 228, 0, 255],
        [255, 230, 0, 255],
        [255, 233, 0, 255],
        [255, 235, 0, 255],
        [255, 238, 0, 255],
        [255, 241, 0, 255],
        [255, 243, 0, 255],
        [255, 246, 0, 255],
        [255, 249, 0, 255],
        [255, 251, 0, 255],
        [255, 254, 0, 255],
        [255, 255, 2, 255],
        [255, 255, 6, 255],
        [255, 255, 10, 255],
        [255, 255, 14, 255],
        [255, 255, 18, 255],
        [255, 255, 22, 255],
        [255, 255, 26, 255],
        [255, 255, 30, 255],
        [255, 255, 34, 255],
        [255, 255, 38, 255],
        [255, 255, 42, 255],
        [255, 255, 46, 255],
        [255, 255, 50, 255],
        [255, 255, 54, 255],
        [255, 255, 58, 255],
        [255, 255, 62, 255],
        [255, 255, 65, 255],
        [255, 255, 69, 255],
        [255, 255, 73, 255],
        [255, 255, 77, 255],
        [255, 255, 81, 255],
        [255, 255, 85, 255],
        [255, 255, 89, 255],
        [255, 255, 93, 255],
        [255, 255, 97, 255],
        [255, 255, 101, 255],
        [255, 255, 105, 255],
        [255, 255, 109, 255],
        [255, 255, 113, 255],
        [255, 255, 117, 255],
        [255, 255, 121, 255],
        [255, 255, 125, 255],
        [255, 255, 128, 255],
        [255, 255, 132, 255],
        [255, 255, 136, 255],
        [255, 255, 140, 255],
        [255, 255, 144, 255],
        [255, 255, 148, 255],
        [255, 255, 152, 255],
        [255, 255, 156, 255],
        [255, 255, 160, 255],
        [255, 255, 164, 255],
        [255, 255, 168, 255],
        [255, 255, 172, 255],
        [255, 255, 176, 255],
        [255, 255, 180, 255],
        [255, 255, 184, 255],
        [255, 255, 188, 255],
        [255, 255, 191, 255],
        [255, 255, 195, 255],
        [255, 255, 199, 255],
        [255, 255, 203, 255],
        [255, 255, 207, 255],
        [255, 255, 211, 255],
        [255, 255, 215, 255],
        [255, 255, 219, 255],
        [255, 255, 223, 255],
        [255, 255, 227, 255],
        [255, 255, 231, 255],
        [255, 255, 235, 255],
        [255, 255, 239, 255],
        [255, 255, 243, 255],
        [255, 255, 247, 255],
        [255, 255, 251, 255],
        [255, 255, 255, 255],
    ],
    spectral: [
        [158, 1, 66, 255],
        [160, 3, 66, 255],
        [162, 5, 67, 255],
        [164, 8, 67, 255],
        [166, 10, 68, 255],
        [168, 12, 68, 255],
        [170, 15, 69, 255],
        [173, 17, 69, 255],
        [175, 20, 70, 255],
        [177, 22, 70, 255],
        [179, 24, 71, 255],
        [181, 27, 71, 255],
        [183, 29, 72, 255],
        [186, 32, 72, 255],
        [188, 34, 73, 255],
        [190, 36, 73, 255],
        [192, 39, 74, 255],
        [194, 41, 74, 255],
        [196, 44, 75, 255],
        [198, 46, 75, 255],
        [201, 48, 76, 255],
        [203, 51, 76, 255],
        [205, 53, 77, 255],
        [207, 56, 77, 255],
        [209, 58, 78, 255],
        [211, 60, 78, 255],
        [213, 62, 78, 255],
        [214, 64, 78, 255],
        [216, 66, 77, 255],
        [217, 68, 77, 255],
        [218, 70, 76, 255],
        [219, 72, 76, 255],
        [220, 73, 75, 255],
        [222, 75, 75, 255],
        [223, 77, 75, 255],
        [224, 79, 74, 255],
        [225, 81, 74, 255],
        [226, 83, 73, 255],
        [228, 85, 73, 255],
        [229, 86, 72, 255],
        [230, 88, 72, 255],
        [231, 90, 71, 255],
        [233, 92, 71, 255],
        [234, 94, 70, 255],
        [235, 96, 70, 255],
        [236, 97, 69, 255],
        [237, 99, 69, 255],
        [239, 101, 68, 255],
        [240, 103, 68, 255],
        [241, 105, 67, 255],
        [242, 107, 67, 255],
        [244, 109, 67, 255],
        [244, 111, 68, 255],
        [244, 114, 69, 255],
        [245, 116, 70, 255],
        [245, 119, 71, 255],
        [245, 121, 72, 255],
        [246, 124, 74, 255],
        [246, 126, 75, 255],
        [246, 129, 76, 255],
        [247, 131, 77, 255],
        [247, 134, 78, 255],
        [247, 137, 79, 255],
        [248, 139, 81, 255],
        [248, 142, 82, 255],
        [248, 144, 83, 255],
        [249, 147, 84, 255],
        [249, 149, 85, 255],
        [250, 152, 86, 255],
        [250, 154, 88, 255],
        [250, 157, 89, 255],
        [251, 159, 90, 255],
        [251, 162, 91, 255],
        [251, 165, 92, 255],
        [252, 167, 94, 255],
        [252, 170, 95, 255],
        [252, 172, 96, 255],
        [253, 174, 97, 255],
        [253, 176, 99, 255],
        [253, 178, 101, 255],
        [253, 180, 102, 255],
        [253, 182, 104, 255],
        [253, 184, 106, 255],
        [253, 186, 107, 255],
        [253, 188, 109, 255],
        [253, 190, 110, 255],
        [253, 192, 112, 255],
        [253, 194, 114, 255],
        [253, 196, 115, 255],
        [253, 198, 117, 255],
        [253, 200, 119, 255],
        [253, 202, 120, 255],
        [253, 204, 122, 255],
        [253, 206, 124, 255],
        [253, 208, 125, 255],
        [253, 210, 127, 255],
        [253, 212, 129, 255],
        [253, 214, 130, 255],
        [253, 216, 132, 255],
        [253, 218, 134, 255],
        [253, 220, 135, 255],
        [253, 222, 137, 255],
        [254, 224, 139, 255],
        [254, 225, 141, 255],
        [254, 226, 143, 255],
        [254, 227, 145, 255],
        [254, 228, 147, 255],
        [254, 230, 149, 255],
        [254, 231, 151, 255],
        [254, 232, 153, 255],
        [254, 233, 155, 255],
        [254, 234, 157, 255],
        [254, 236, 159, 255],
        [254, 237, 161, 255],
        [254, 238, 163, 255],
        [254, 239, 165, 255],
        [254, 241, 167, 255],
        [254, 242, 169, 255],
        [254, 243, 171, 255],
        [254, 244, 173, 255],
        [254, 245, 175, 255],
        [254, 247, 177, 255],
        [254, 248, 179, 255],
        [254, 249, 181, 255],
        [254, 250, 183, 255],
        [254, 251, 185, 255],
        [254, 253, 187, 255],
        [254, 254, 189, 255],
        [254, 254, 190, 255],
        [253, 254, 188, 255],
        [252, 254, 187, 255],
        [251, 253, 185, 255],
        [250, 253, 184, 255],
        [249, 252, 182, 255],
        [248, 252, 181, 255],
        [247, 252, 179, 255],
        [246, 251, 178, 255],
        [245, 251, 176, 255],
        [244, 250, 174, 255],
        [243, 250, 173, 255],
        [242, 250, 171, 255],
        [241, 249, 170, 255],
        [240, 249, 168, 255],
        [239, 248, 167, 255],
        [238, 248, 165, 255],
        [237, 248, 164, 255],
        [236, 247, 162, 255],
        [235, 247, 161, 255],
        [234, 246, 159, 255],
        [233, 246, 158, 255],
        [232, 246, 156, 255],
        [231, 245, 155, 255],
        [230, 245, 153, 255],
        [230, 245, 152, 255],
        [227, 244, 152, 255],
        [225, 243, 152, 255],
        [223, 242, 153, 255],
        [220, 241, 153, 255],
        [218, 240, 154, 255],
        [216, 239, 154, 255],
        [213, 238, 155, 255],
        [211, 237, 155, 255],
        [209, 236, 156, 255],
        [206, 235, 156, 255],
        [204, 234, 157, 255],
        [202, 233, 157, 255],
        [199, 232, 158, 255],
        [197, 231, 158, 255],
        [195, 230, 159, 255],
        [192, 229, 159, 255],
        [190, 229, 160, 255],
        [188, 228, 160, 255],
        [186, 227, 160, 255],
        [183, 226, 161, 255],
        [181, 225, 161, 255],
        [179, 224, 162, 255],
        [176, 223, 162, 255],
        [174, 222, 163, 255],
        [172, 221, 163, 255],
        [169, 220, 164, 255],
        [166, 219, 164, 255],
        [164, 218, 164, 255],
        [161, 217, 164, 255],
        [158, 216, 164, 255],
        [156, 215, 164, 255],
        [153, 214, 164, 255],
        [150, 213, 164, 255],
        [148, 212, 164, 255],
        [145, 210, 164, 255],
        [142, 209, 164, 255],
        [139, 208, 164, 255],
        [137, 207, 164, 255],
        [134, 206, 164, 255],
        [131, 205, 164, 255],
        [129, 204, 164, 255],
        [126, 203, 164, 255],
        [123, 202, 164, 255],
        [120, 201, 164, 255],
        [118, 200, 164, 255],
        [115, 199, 164, 255],
        [112, 198, 164, 255],
        [110, 197, 164, 255],
        [107, 196, 164, 255],
        [104, 195, 164, 255],
        [102, 194, 165, 255],
        [99, 191, 165, 255],
        [97, 189, 166, 255],
        [95, 187, 167, 255],
        [93, 184, 168, 255],
        [91, 182, 169, 255],
        [89, 180, 170, 255],
        [87, 178, 171, 255],
        [85, 175, 172, 255],
        [83, 173, 173, 255],
        [81, 171, 174, 255],
        [79, 168, 175, 255],
        [77, 166, 176, 255],
        [75, 164, 177, 255],
        [73, 162, 178, 255],
        [71, 159, 179, 255],
        [69, 157, 180, 255],
        [67, 155, 181, 255],
        [65, 153, 181, 255],
        [63, 150, 182, 255],
        [61, 148, 183, 255],
        [59, 146, 184, 255],
        [57, 143, 185, 255],
        [55, 141, 186, 255],
        [53, 139, 187, 255],
        [51, 137, 188, 255],
        [50, 134, 188, 255],
        [52, 132, 187, 255],
        [54, 130, 186, 255],
        [56, 128, 185, 255],
        [57, 125, 184, 255],
        [59, 123, 183, 255],
        [61, 121, 182, 255],
        [62, 119, 181, 255],
        [64, 117, 180, 255],
        [66, 114, 178, 255],
        [68, 112, 177, 255],
        [69, 110, 176, 255],
        [71, 108, 175, 255],
        [73, 105, 174, 255],
        [75, 103, 173, 255],
        [76, 101, 172, 255],
        [78, 99, 171, 255],
        [80, 96, 170, 255],
        [81, 94, 169, 255],
        [83, 92, 168, 255],
        [85, 90, 167, 255],
        [87, 87, 166, 255],
        [88, 85, 165, 255],
        [90, 83, 164, 255],
        [92, 81, 163, 255],
        [94, 79, 162, 255],
    ],
    inferno: [
        [0, 0, 3, 255],
        [0, 0, 4, 255],
        [0, 0, 6, 255],
        [1, 0, 7, 255],
        [1, 1, 9, 255],
        [1, 1, 11, 255],
        [2, 1, 14, 255],
        [2, 2, 16, 255],
        [3, 2, 18, 255],
        [4, 3, 20, 255],
        [4, 3, 22, 255],
        [5, 4, 24, 255],
        [6, 4, 27, 255],
        [7, 5, 29, 255],
        [8, 6, 31, 255],
        [9, 6, 33, 255],
        [10, 7, 35, 255],
        [11, 7, 38, 255],
        [13, 8, 40, 255],
        [14, 8, 42, 255],
        [15, 9, 45, 255],
        [16, 9, 47, 255],
        [18, 10, 50, 255],
        [19, 10, 52, 255],
        [20, 11, 54, 255],
        [22, 11, 57, 255],
        [23, 11, 59, 255],
        [25, 11, 62, 255],
        [26, 11, 64, 255],
        [28, 12, 67, 255],
        [29, 12, 69, 255],
        [31, 12, 71, 255],
        [32, 12, 74, 255],
        [34, 11, 76, 255],
        [36, 11, 78, 255],
        [38, 11, 80, 255],
        [39, 11, 82, 255],
        [41, 11, 84, 255],
        [43, 10, 86, 255],
        [45, 10, 88, 255],
        [46, 10, 90, 255],
        [48, 10, 92, 255],
        [50, 9, 93, 255],
        [52, 9, 95, 255],
        [53, 9, 96, 255],
        [55, 9, 97, 255],
        [57, 9, 98, 255],
        [59, 9, 100, 255],
        [60, 9, 101, 255],
        [62, 9, 102, 255],
        [64, 9, 102, 255],
        [65, 9, 103, 255],
        [67, 10, 104, 255],
        [69, 10, 105, 255],
        [70, 10, 105, 255],
        [72, 11, 106, 255],
        [74, 11, 106, 255],
        [75, 12, 107, 255],
        [77, 12, 107, 255],
        [79, 13, 108, 255],
        [80, 13, 108, 255],
        [82, 14, 108, 255],
        [83, 14, 109, 255],
        [85, 15, 109, 255],
        [87, 15, 109, 255],
        [88, 16, 109, 255],
        [90, 17, 109, 255],
        [91, 17, 110, 255],
        [93, 18, 110, 255],
        [95, 18, 110, 255],
        [96, 19, 110, 255],
        [98, 20, 110, 255],
        [99, 20, 110, 255],
        [101, 21, 110, 255],
        [102, 21, 110, 255],
        [104, 22, 110, 255],
        [106, 23, 110, 255],
        [107, 23, 110, 255],
        [109, 24, 110, 255],
        [110, 24, 110, 255],
        [112, 25, 110, 255],
        [114, 25, 109, 255],
        [115, 26, 109, 255],
        [117, 27, 109, 255],
        [118, 27, 109, 255],
        [120, 28, 109, 255],
        [122, 28, 109, 255],
        [123, 29, 108, 255],
        [125, 29, 108, 255],
        [126, 30, 108, 255],
        [128, 31, 107, 255],
        [129, 31, 107, 255],
        [131, 32, 107, 255],
        [133, 32, 106, 255],
        [134, 33, 106, 255],
        [136, 33, 106, 255],
        [137, 34, 105, 255],
        [139, 34, 105, 255],
        [141, 35, 105, 255],
        [142, 36, 104, 255],
        [144, 36, 104, 255],
        [145, 37, 103, 255],
        [147, 37, 103, 255],
        [149, 38, 102, 255],
        [150, 38, 102, 255],
        [152, 39, 101, 255],
        [153, 40, 100, 255],
        [155, 40, 100, 255],
        [156, 41, 99, 255],
        [158, 41, 99, 255],
        [160, 42, 98, 255],
        [161, 43, 97, 255],
        [163, 43, 97, 255],
        [164, 44, 96, 255],
        [166, 44, 95, 255],
        [167, 45, 95, 255],
        [169, 46, 94, 255],
        [171, 46, 93, 255],
        [172, 47, 92, 255],
        [174, 48, 91, 255],
        [175, 49, 91, 255],
        [177, 49, 90, 255],
        [178, 50, 89, 255],
        [180, 51, 88, 255],
        [181, 51, 87, 255],
        [183, 52, 86, 255],
        [184, 53, 86, 255],
        [186, 54, 85, 255],
        [187, 55, 84, 255],
        [189, 55, 83, 255],
        [190, 56, 82, 255],
        [191, 57, 81, 255],
        [193, 58, 80, 255],
        [194, 59, 79, 255],
        [196, 60, 78, 255],
        [197, 61, 77, 255],
        [199, 62, 76, 255],
        [200, 62, 75, 255],
        [201, 63, 74, 255],
        [203, 64, 73, 255],
        [204, 65, 72, 255],
        [205, 66, 71, 255],
        [207, 68, 70, 255],
        [208, 69, 68, 255],
        [209, 70, 67, 255],
        [210, 71, 66, 255],
        [212, 72, 65, 255],
        [213, 73, 64, 255],
        [214, 74, 63, 255],
        [215, 75, 62, 255],
        [217, 77, 61, 255],
        [218, 78, 59, 255],
        [219, 79, 58, 255],
        [220, 80, 57, 255],
        [221, 82, 56, 255],
        [222, 83, 55, 255],
        [223, 84, 54, 255],
        [224, 86, 52, 255],
        [226, 87, 51, 255],
        [227, 88, 50, 255],
        [228, 90, 49, 255],
        [229, 91, 48, 255],
        [230, 92, 46, 255],
        [230, 94, 45, 255],
        [231, 95, 44, 255],
        [232, 97, 43, 255],
        [233, 98, 42, 255],
        [234, 100, 40, 255],
        [235, 101, 39, 255],
        [236, 103, 38, 255],
        [237, 104, 37, 255],
        [237, 106, 35, 255],
        [238, 108, 34, 255],
        [239, 109, 33, 255],
        [240, 111, 31, 255],
        [240, 112, 30, 255],
        [241, 114, 29, 255],
        [242, 116, 28, 255],
        [242, 117, 26, 255],
        [243, 119, 25, 255],
        [243, 121, 24, 255],
        [244, 122, 22, 255],
        [245, 124, 21, 255],
        [245, 126, 20, 255],
        [246, 128, 18, 255],
        [246, 129, 17, 255],
        [247, 131, 16, 255],
        [247, 133, 14, 255],
        [248, 135, 13, 255],
        [248, 136, 12, 255],
        [248, 138, 11, 255],
        [249, 140, 9, 255],
        [249, 142, 8, 255],
        [249, 144, 8, 255],
        [250, 145, 7, 255],
        [250, 147, 6, 255],
        [250, 149, 6, 255],
        [250, 151, 6, 255],
        [251, 153, 6, 255],
        [251, 155, 6, 255],
        [251, 157, 6, 255],
        [251, 158, 7, 255],
        [251, 160, 7, 255],
        [251, 162, 8, 255],
        [251, 164, 10, 255],
        [251, 166, 11, 255],
        [251, 168, 13, 255],
        [251, 170, 14, 255],
        [251, 172, 16, 255],
        [251, 174, 18, 255],
        [251, 176, 20, 255],
        [251, 177, 22, 255],
        [251, 179, 24, 255],
        [251, 181, 26, 255],
        [251, 183, 28, 255],
        [251, 185, 30, 255],
        [250, 187, 33, 255],
        [250, 189, 35, 255],
        [250, 191, 37, 255],
        [250, 193, 40, 255],
        [249, 195, 42, 255],
        [249, 197, 44, 255],
        [249, 199, 47, 255],
        [248, 201, 49, 255],
        [248, 203, 52, 255],
        [248, 205, 55, 255],
        [247, 207, 58, 255],
        [247, 209, 60, 255],
        [246, 211, 63, 255],
        [246, 213, 66, 255],
        [245, 215, 69, 255],
        [245, 217, 72, 255],
        [244, 219, 75, 255],
        [244, 220, 79, 255],
        [243, 222, 82, 255],
        [243, 224, 86, 255],
        [243, 226, 89, 255],
        [242, 228, 93, 255],
        [242, 230, 96, 255],
        [241, 232, 100, 255],
        [241, 233, 104, 255],
        [241, 235, 108, 255],
        [241, 237, 112, 255],
        [241, 238, 116, 255],
        [241, 240, 121, 255],
        [241, 242, 125, 255],
        [242, 243, 129, 255],
        [242, 244, 133, 255],
        [243, 246, 137, 255],
        [244, 247, 141, 255],
        [245, 248, 145, 255],
        [246, 250, 149, 255],
        [247, 251, 153, 255],
        [249, 252, 157, 255],
        [250, 253, 160, 255],
        [252, 254, 164, 255],
    ],
    cmrmap: [
        [0, 0, 0, 255],
        [1, 1, 4, 255],
        [2, 2, 8, 255],
        [3, 3, 12, 255],
        [4, 4, 16, 255],
        [6, 6, 20, 255],
        [7, 7, 24, 255],
        [8, 8, 28, 255],
        [9, 9, 32, 255],
        [10, 10, 36, 255],
        [12, 12, 40, 255],
        [13, 13, 44, 255],
        [14, 14, 48, 255],
        [15, 15, 52, 255],
        [16, 16, 56, 255],
        [18, 18, 60, 255],
        [19, 19, 64, 255],
        [20, 20, 68, 255],
        [21, 21, 72, 255],
        [22, 22, 76, 255],
        [24, 24, 80, 255],
        [25, 25, 84, 255],
        [26, 26, 88, 255],
        [27, 27, 92, 255],
        [28, 28, 96, 255],
        [30, 30, 100, 255],
        [31, 31, 104, 255],
        [32, 32, 108, 255],
        [33, 33, 112, 255],
        [34, 34, 116, 255],
        [36, 36, 120, 255],
        [37, 37, 124, 255],
        [38, 38, 127, 255],
        [39, 38, 129, 255],
        [40, 38, 131, 255],
        [42, 38, 133, 255],
        [43, 38, 135, 255],
        [44, 38, 137, 255],
        [45, 38, 139, 255],
        [46, 38, 141, 255],
        [48, 38, 143, 255],
        [49, 38, 145, 255],
        [50, 38, 147, 255],
        [51, 38, 149, 255],
        [52, 38, 151, 255],
        [53, 38, 153, 255],
        [55, 38, 155, 255],
        [56, 38, 157, 255],
        [57, 38, 159, 255],
        [58, 38, 161, 255],
        [60, 38, 163, 255],
        [61, 38, 165, 255],
        [62, 38, 167, 255],
        [63, 38, 169, 255],
        [64, 38, 171, 255],
        [65, 38, 173, 255],
        [67, 38, 175, 255],
        [68, 38, 177, 255],
        [69, 38, 179, 255],
        [70, 38, 181, 255],
        [72, 38, 183, 255],
        [73, 38, 185, 255],
        [74, 38, 187, 255],
        [75, 38, 189, 255],
        [77, 38, 190, 255],
        [79, 38, 188, 255],
        [81, 39, 186, 255],
        [84, 39, 184, 255],
        [86, 39, 182, 255],
        [89, 40, 180, 255],
        [91, 40, 178, 255],
        [93, 41, 176, 255],
        [96, 41, 174, 255],
        [98, 41, 172, 255],
        [101, 42, 170, 255],
        [103, 42, 168, 255],
        [105, 43, 166, 255],
        [108, 43, 164, 255],
        [110, 43, 162, 255],
        [113, 44, 160, 255],
        [115, 44, 158, 255],
        [117, 45, 156, 255],
        [120, 45, 154, 255],
        [122, 45, 152, 255],
        [125, 46, 150, 255],
        [127, 46, 148, 255],
        [129, 47, 146, 255],
        [132, 47, 144, 255],
        [134, 47, 142, 255],
        [137, 48, 140, 255],
        [139, 48, 138, 255],
        [141, 49, 136, 255],
        [144, 49, 134, 255],
        [146, 49, 132, 255],
        [149, 50, 130, 255],
        [151, 50, 128, 255],
        [154, 51, 126, 255],
        [157, 51, 123, 255],
        [160, 51, 120, 255],
        [163, 52, 118, 255],
        [167, 52, 115, 255],
        [170, 53, 112, 255],
        [173, 53, 109, 255],
        [176, 53, 106, 255],
        [179, 54, 104, 255],
        [183, 54, 101, 255],
        [186, 55, 98, 255],
        [189, 55, 95, 255],
        [192, 55, 92, 255],
        [195, 56, 90, 255],
        [199, 56, 87, 255],
        [202, 57, 84, 255],
        [205, 57, 81, 255],
        [208, 57, 78, 255],
        [211, 58, 76, 255],
        [215, 58, 73, 255],
        [218, 59, 70, 255],
        [221, 59, 67, 255],
        [224, 59, 64, 255],
        [227, 60, 62, 255],
        [231, 60, 59, 255],
        [234, 61, 56, 255],
        [237, 61, 53, 255],
        [240, 61, 50, 255],
        [243, 62, 48, 255],
        [247, 62, 45, 255],
        [250, 63, 42, 255],
        [253, 63, 39, 255],
        [254, 64, 37, 255],
        [253, 66, 36, 255],
        [253, 68, 35, 255],
        [252, 70, 34, 255],
        [251, 72, 32, 255],
        [250, 74, 31, 255],
        [249, 76, 30, 255],
        [249, 78, 29, 255],
        [248, 80, 28, 255],
        [247, 82, 26, 255],
        [246, 84, 25, 255],
        [245, 86, 24, 255],
        [245, 88, 23, 255],
        [244, 90, 22, 255],
        [243, 92, 20, 255],
        [242, 94, 19, 255],
        [241, 96, 18, 255],
        [241, 98, 17, 255],
        [240, 100, 16, 255],
        [239, 102, 14, 255],
        [238, 104, 13, 255],
        [237, 106, 12, 255],
        [237, 108, 11, 255],
        [236, 110, 10, 255],
        [235, 112, 8, 255],
        [234, 114, 7, 255],
        [233, 116, 6, 255],
        [233, 118, 5, 255],
        [232, 120, 4, 255],
        [231, 122, 2, 255],
        [230, 124, 1, 255],
        [229, 126, 0, 255],
        [229, 128, 0, 255],
        [229, 130, 1, 255],
        [229, 132, 2, 255],
        [229, 134, 2, 255],
        [229, 136, 3, 255],
        [229, 138, 4, 255],
        [229, 140, 5, 255],
        [229, 142, 6, 255],
        [229, 144, 6, 255],
        [229, 146, 7, 255],
        [229, 148, 8, 255],
        [229, 150, 9, 255],
        [229, 152, 10, 255],
        [229, 154, 10, 255],
        [229, 156, 11, 255],
        [229, 158, 12, 255],
        [229, 160, 13, 255],
        [229, 162, 14, 255],
        [229, 164, 14, 255],
        [229, 166, 15, 255],
        [229, 168, 16, 255],
        [229, 170, 17, 255],
        [229, 172, 18, 255],
        [229, 174, 18, 255],
        [229, 176, 19, 255],
        [229, 178, 20, 255],
        [229, 180, 21, 255],
        [229, 182, 22, 255],
        [229, 184, 22, 255],
        [229, 186, 23, 255],
        [229, 188, 24, 255],
        [229, 190, 25, 255],
        [229, 192, 27, 255],
        [229, 193, 31, 255],
        [229, 194, 34, 255],
        [229, 195, 37, 255],
        [229, 196, 40, 255],
        [229, 198, 43, 255],
        [229, 199, 47, 255],
        [229, 200, 50, 255],
        [229, 201, 53, 255],
        [229, 202, 56, 255],
        [229, 204, 59, 255],
        [229, 205, 63, 255],
        [229, 206, 66, 255],
        [229, 207, 69, 255],
        [229, 208, 72, 255],
        [229, 210, 75, 255],
        [229, 211, 79, 255],
        [229, 212, 82, 255],
        [229, 213, 85, 255],
        [229, 214, 88, 255],
        [229, 216, 91, 255],
        [229, 217, 95, 255],
        [229, 218, 98, 255],
        [229, 219, 101, 255],
        [229, 220, 104, 255],
        [229, 222, 107, 255],
        [229, 223, 111, 255],
        [229, 224, 114, 255],
        [229, 225, 117, 255],
        [229, 226, 120, 255],
        [229, 228, 123, 255],
        [229, 229, 127, 255],
        [230, 230, 131, 255],
        [231, 231, 135, 255],
        [231, 231, 139, 255],
        [232, 232, 143, 255],
        [233, 233, 146, 255],
        [234, 234, 151, 255],
        [235, 235, 155, 255],
        [235, 235, 159, 255],
        [236, 236, 163, 255],
        [237, 237, 167, 255],
        [238, 238, 171, 255],
        [239, 239, 175, 255],
        [239, 239, 179, 255],
        [240, 240, 183, 255],
        [241, 241, 187, 255],
        [242, 242, 191, 255],
        [243, 243, 195, 255],
        [243, 243, 199, 255],
        [244, 244, 203, 255],
        [245, 245, 207, 255],
        [246, 246, 210, 255],
        [247, 247, 215, 255],
        [247, 247, 219, 255],
        [248, 248, 223, 255],
        [249, 249, 227, 255],
        [250, 250, 231, 255],
        [251, 251, 235, 255],
        [251, 251, 239, 255],
        [252, 252, 243, 255],
        [253, 253, 247, 255],
        [254, 254, 251, 255],
        [255, 255, 255, 255],
    ],
    tab10: [
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
    ],
    tab20: [
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [31, 119, 180, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [174, 199, 232, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 127, 14, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [255, 187, 120, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [44, 160, 44, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [152, 223, 138, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [214, 39, 40, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [255, 152, 150, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [148, 103, 189, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [197, 176, 213, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [140, 86, 75, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [196, 156, 148, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [227, 119, 194, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [247, 182, 210, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [127, 127, 127, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [199, 199, 199, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [188, 189, 34, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [219, 219, 141, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [23, 190, 207, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
        [158, 218, 229, 255],
    ],
};
